@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,900);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400);
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

b, strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

sub, sup, small {
  font-size: 75%; }

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after, .clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ''; }

* {
  box-sizing: border-box; }

body {
  background: -webkit-linear-gradient(top, #080B1B 0%, #0D172A 100%);
  background: linear-gradient(180deg, #080B1B 0%, #0D172A 100%);
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  color: #ffffff; }
  body::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  body::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #192036; }
  body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #6F7481; }
  body::-webkit-scrollbar-thumb:window-inactive {
    background: #6F7481; }

.page_wrapper {
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  width: 100%;
  padding: 80px 40px; }

a, button {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  background: transparent; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type='number'] {
  -moz-appearance: textfield; }

.text_hover {
  cursor: pointer;
  -webkit-transition: all ease .3s;
  transition: all ease .3s; }
  .text_hover:hover {
    opacity: 0.5; }

.card_hover {
  cursor: pointer;
  -webkit-transition: all ease .2s;
  transition: all ease .2s; }
  .card_hover:hover {
    margin-top: -3px;
    margin-bottom: 3px; }

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Inter-Regular.bffaed79.woff2) format("woff2"), url(/static/media/Inter-Regular.aebfbb3c.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/Inter-Italic.381444ec.woff2) format("woff2"), url(/static/media/Inter-Italic.35cf8109.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/Inter-Medium.2e5e0884.woff2) format("woff2"), url(/static/media/Inter-Medium.c09fb389.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url(/static/media/Inter-MediumItalic.7a7fd735.woff2) format("woff2"), url(/static/media/Inter-MediumItalic.ad6e093c.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBold.cd04cacd.woff2) format("woff2"), url(/static/media/Inter-SemiBold.acc6eebd.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBoldItalic.57262d35.woff2) format("woff2"), url(/static/media/Inter-SemiBoldItalic.569061c2.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Inter-Bold.34356f6b.woff2) format("woff2"), url(/static/media/Inter-Bold.61c493e3.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/Inter-BoldItalic.57acb479.woff2) format("woff2"), url(/static/media/Inter-BoldItalic.6fbcf86a.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBold.a627e2ce.woff2) format("woff2"), url(/static/media/Inter-ExtraBold.0b03428a.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBoldItalic.b95f0df1.woff2) format("woff2"), url(/static/media/Inter-ExtraBoldItalic.137fcd98.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/Inter-Black.c7a3bad1.woff2) format("woff2"), url(/static/media/Inter-Black.a4502329.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  src: url(/static/media/Inter-BlackItalic.d000d76e.woff2) format("woff2"), url(/static/media/Inter-BlackItalic.094751f4.woff) format("woff"); }

.loader_wrapper {
  width: 100%;
  height: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }

.loading {
  position: absolute;
  top: 0;
  font-weight: 500;
  font-size: 24px; }
  .loading::after {
    content: '.';
    -webkit-animation: dots 0.5s steps(5, end) infinite;
            animation: dots 0.5s steps(5, end) infinite; }

@-webkit-keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0); }
  80%, 100% {
    text-shadow: .25em 0 0 white, .5em 0 0 white; } }

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0); }
  80%, 100% {
    text-shadow: .25em 0 0 white, .5em 0 0 white; } }

.loader_container {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 200px; }

.dashboard_wrapper {
  width: 100%;
  background: #192036;
  border-radius: 9px;
  padding: 40px; }
  .dashboard_wrapper .tabs_wrapper {
    position: relative; }
    .dashboard_wrapper .tabs_wrapper:before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: #373947;
      bottom: 0; }
    .dashboard_wrapper .tabs_wrapper .tab_wrapper {
      min-width: unset;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
  .dashboard_wrapper .periods_wrapper {
    display: flex;
    margin: 40px 14px; }
    .dashboard_wrapper .periods_wrapper .period {
      margin-right: 32px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.25px;
      color: #FFFFFF;
      opacity: 0.5;
      -webkit-transition: all ease .3s;
      transition: all ease .3s;
      cursor: pointer; }
      .dashboard_wrapper .periods_wrapper .period:hover {
        opacity: 1; }
      .dashboard_wrapper .periods_wrapper .period.active {
        opacity: 1; }
  .dashboard_wrapper .chart_wrapper {
    margin: 24px 14px 10px; }

.default_dialog_root > :first-child {
  background-color: rgba(5, 9, 18, 0.8); }

.default_dialog_root > :nth-child(3) > :first-child {
  background: transparent;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  max-width: 698px !important; }

.default_dialog_root .dialog_close_button {
  cursor: pointer;
  position: absolute;
  right: 26.5px;
  top: 31.5px;
  padding: 9.5px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid rgba(126, 141, 156, 0.6); }
  .default_dialog_root .dialog_close_button img {
    width: 10px;
    height: 10px; }

.dialog_wrapper {
  background: #0E1832;
  border: 1px solid #373947;
  box-sizing: border-box;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative; }

.how_to_submit_results {
  padding: 20px 20px 40px; }
  .how_to_submit_results img {
    margin-left: auto;
    cursor: pointer; }
  .how_to_submit_results h2 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    letter-spacing: -0.5824px;
    color: #FFFFFF;
    margin-bottom: 20px; }

.default_button_wrapper .default_button {
  border-radius: 26.78px;
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-transform: none;
  padding: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .default_button_wrapper .default_button.default_button_green {
    background: -webkit-linear-gradient(right, #2FC272 0%, #33BC71 100%);
    background: linear-gradient(270deg, #2FC272 0%, #33BC71 100%); }
    .default_button_wrapper .default_button.default_button_green:disabled {
      color: white;
      opacity: 0.5; }
  .default_button_wrapper .default_button.default_button_white {
    background: #0E1832;
    border: 1px solid #fff;
    color: #FFFFFF; }
  .default_button_wrapper .default_button.default_button_red {
    background: #F32A2A; }
    .default_button_wrapper .default_button.default_button_red:disabled {
      color: white;
      opacity: 0.5; }
  .default_button_wrapper .default_button.default_button_transparent {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
    background: transparent;
    border: 1px solid #fff; }
  .default_button_wrapper .default_button.default_button_big {
    height: 50px;
    width: 216px;
    line-height: 50px; }
  .default_button_wrapper .default_button.default_button_large {
    height: 45px;
    width: 300px;
    line-height: 45px; }
  .default_button_wrapper .default_button.default_button_medium {
    height: 50px;
    width: 175px;
    line-height: 50px; }
  .default_button_wrapper .default_button.default_button_mediocre {
    height: 50px;
    width: 146px;
    line-height: 50px; }
  .default_button_wrapper .default_button.default_button_sf_small {
    height: 40px;
    width: 146px;
    line-height: 40px;
    margin-bottom: 4px; }
  .default_button_wrapper .default_button.default_button_smallest {
    height: 26px;
    width: 90px;
    line-height: 26px; }
  .default_button_wrapper .default_button.default_button_small {
    height: 41px;
    width: 92px;
    line-height: 41px; }
  .default_button_wrapper .default_button.default_button_xbox {
    background: #1A6444;
    border-radius: 4px;
    height: 40px;
    width: 180px;
    line-height: 40px; }
  .default_button_wrapper .default_button.default_button_psn {
    background: #3A67D0;
    border-radius: 4px;
    height: 40px;
    width: 180px;
    line-height: 40px; }
  .default_button_wrapper .default_button.default_button_steam {
    background: #88B940;
    border-radius: 4px;
    height: 40px;
    width: 180px;
    line-height: 40px;
    font-size: 12px; }
    .default_button_wrapper .default_button.default_button_steam img {
      margin-right: 7px; }
  .default_button_wrapper .default_button.default_button_quit {
    background: transparent;
    border: 1px solid #F75151;
    border-radius: 26.7836px;
    color: #F32A2A;
    font-weight: 600;
    font-size: 14px; }
  .default_button_wrapper .default_button.default_button_submit_results {
    box-shadow: 0 7px 12px rgba(47, 194, 114, 0.34);
    border-radius: 31px;
    font-weight: 600;
    font-size: 14px;
    height: 50px;
    width: 246px; }
  .default_button_wrapper .default_button .btn_progress {
    color: #ffffff; }
  .default_button_wrapper .default_button.default_button_last_played_game_rematch {
    border-radius: 54px;
    width: 190px;
    height: 40px; }
  .default_button_wrapper .default_button.default_button_toast {
    border-radius: 27px;
    width: 160px;
    height: 34px;
    margin-left: 10px; }
  .default_button_wrapper .default_button.default_button_connect_profile {
    width: 180px;
    height: 40px;
    background: #88B940;
    border-radius: 4px;
    line-height: unset;
    font-weight: 500;
    font-size: 12px; }
  .default_button_wrapper .default_button.default_button_blogger_size {
    width: 100%;
    height: 44px;
    background: #2FC272;
    border-radius: 46px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px; }
  .default_button_wrapper .default_button.default_button_paysafecard {
    width: 218px;
    height: 45px;
    background: #2FC272;
    border-radius: 22.5px;
    font-size: 14px;
    line-height: 17px; }

.blogger_header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #0E1832;
  padding: 0 40px;
  z-index: 99; }

.header-logo > :first-child {
  margin-right: 20px; }

.header_options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60%;
  color: #ffffff; }
  .header_options svg {
    fill: #ffffff; }
  .header_options > button {
    max-width: 50%; }
  .header_options button {
    line-height: 2 !important;
    padding: 0;
    display: flex;
    min-width: unset !important; }
    .header_options button p {
      color: #ffffff;
      font-family: "Inter", sans-serif;
      font-size: 13px;
      margin: 0 10px;
      text-transform: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .header_options .app-menu_button {
    background: #232841;
    border-radius: 16px; }
    .header_options .app-menu_button svg {
      font-size: 14px;
      margin-right: 5px; }
    .header_options .app-menu_button.menu_open svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.head_btn_wrapper {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative; }

.head_btn_indicator {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 5px;
  background: -webkit-linear-gradient(left, #F85252 0%, #F04242 100%);
  background: linear-gradient(90deg, #F85252 0%, #F04242 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }
  .head_btn_indicator:before {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    left: calc(50% - 5px);
    top: -2px;
    -webkit-clip-path: polygon(50% 0, 0 33%, 100% 33%);
    clip-path: polygon(50% 0, 0 33%, 100% 33%);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-color: #F85252; }
  .head_btn_indicator.indicator_active {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }

div .app_head_popover {
  overflow: inherit;
  background: transparent;
  margin-top: 20px; }

.app-menu {
  width: 138px;
  background-color: #292F4C;
  border: 1px solid #373947;
  padding: 8px 0;
  position: relative;
  border-radius: 6px;
  z-index: 999; }
  .app-menu:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 25px;
    top: -8px;
    border-left: 1px solid #373947;
    border-top: 1px solid #373947;
    background-color: #292F4C;
    -webkit-transform: rotate(45deg) skew(-20deg, -20deg);
    transform: rotate(45deg) skew(-20deg, -20deg);
    z-index: 9999; }
  .app-menu a, .app-menu button {
    padding: 8px 16px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .app-menu a:hover, .app-menu button:hover {
      background-color: rgba(255, 255, 255, 0.1); }

.logout_wrapper {
  text-align: center; }
  .logout_wrapper h3 {
    font-family: Montserrat, sans-serif;
    font-size: 38px;
    font-weight: 600;
    line-height: 47px;
    color: white; }
  .logout_wrapper img {
    margin: 32px 0;
    max-height: 100px; }
  .logout_wrapper p {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
    color: white; }

.logout_buttons {
  margin-bottom: 36px; }
  .logout_buttons .default_button_wrapper {
    display: inline-block; }
  .logout_buttons > :first-child {
    margin-right: 13px; }

.preloader_wrapper {
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 2; }
  .preloader_wrapper > div {
    height: 2px;
    background-color: #0E1832; }
    .preloader_wrapper > div > div {
      background-color: #EF3047; }

.message_popper .message_tooltip {
  background-color: #29323C;
  font-size: 10px;
  word-break: break-word;
  font-family: Montserrat, sans-serif; }

.message_popper .error_message_tooltip {
  background-color: #FF4D62;
  font-size: 10px;
  font-family: Montserrat, sans-serif; }

body .auth_tooltip_popper {
  z-index: 1000000;
  top: 8px;
  left: 9px; }

.custom_input_wrapper {
  width: 100%; }
  .custom_input_wrapper .custom_input_label {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.72);
    font-size: 14px;
    opacity: 0.5;
    -webkit-transform: translate(22px, 20px);
            transform: translate(22px, 20px); }
    .custom_input_wrapper .custom_input_label.custom_input_label_focused {
      color: rgba(255, 255, 255, 0.72);
      -webkit-transform: translate(22px, 10px) scale(0.75);
              transform: translate(22px, 10px) scale(0.75); }
    .custom_input_wrapper .custom_input_label.custom_input_label_active {
      -webkit-transform: translate(22px, 10px) scale(0.75);
              transform: translate(22px, 10px) scale(0.75); }
    .custom_input_wrapper .custom_input_label.custom_input_label_error {
      color: #f44336; }
  .custom_input_wrapper .custom_input {
    font-family: 'Inter', sans-serif;
    background-color: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.72);
    font-size: 14px;
    border-radius: 0; }
    .custom_input_wrapper .custom_input:hover {
      background-color: rgba(255, 255, 255, 0.04); }
      .custom_input_wrapper .custom_input:hover:before {
        border-bottom: 2px solid rgba(255, 255, 255, 0.6) !important; }
    .custom_input_wrapper .custom_input:focus {
      background-color: rgba(255, 255, 255, 0.04); }
    .custom_input_wrapper .custom_input:before {
      border-bottom: 2px solid rgba(255, 255, 255, 0.35); }
    .custom_input_wrapper .custom_input input {
      padding: 26px 22px 9px; }
    .custom_input_wrapper .custom_input.custom_input_focused {
      background-color: rgba(255, 255, 255, 0.04); }
      .custom_input_wrapper .custom_input.custom_input_focused:after {
        border-bottom: 2px solid #FFFFFF; }
    .custom_input_wrapper .custom_input.custom_input_error:after {
      border-bottom: 2px solid #f44336; }
  .custom_input_wrapper .custom_input_adorned div svg {
    fill: #f44336;
    cursor: pointer; }
  .custom_input_wrapper .custom_input_multiline {
    padding: 27px 22px 10px; }
  .custom_input_wrapper .field_clear_btn {
    padding: 2px; }
    .custom_input_wrapper .field_clear_btn img {
      width: 12px; }

.auth_button_wrapper .auth_button {
  text-align: center;
  display: inline-flex;
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 50px;
  height: 50px;
  min-width: 150px;
  padding: 0 20px;
  border-radius: 26.78px;
  box-shadow: none;
  white-space: nowrap;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .auth_button_wrapper .auth_button:disabled {
    opacity: 0.6;
    color: #ffffff; }
  .auth_button_wrapper .auth_button.auth_button_contained {
    background-image: -webkit-linear-gradient(left, #D2273C, #FF344D, #D2273C, #FF344D);
    background-image: linear-gradient(to right, #D2273C, #FF344D, #D2273C, #FF344D);
    border: none;
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; }
    .auth_button_wrapper .auth_button.auth_button_contained div {
      color: #ffffff; }
    .auth_button_wrapper .auth_button.auth_button_contained:hover {
      background-position: 100% 0; }
  .auth_button_wrapper .auth_button.auth_button_green {
    background: #2FC272;
    border: none;
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; }
    .auth_button_wrapper .auth_button.auth_button_green div {
      color: #ffffff; }
    .auth_button_wrapper .auth_button.auth_button_green:hover {
      background-position: 100% 0; }
  .auth_button_wrapper .auth_button.auth_button_outlined {
    color: white;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: relative; }
    .auth_button_wrapper .auth_button.auth_button_outlined:before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 26.78px;
      border: 1px solid white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s; }
    .auth_button_wrapper .auth_button.auth_button_outlined:hover:before {
      opacity: 0;
      -webkit-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2); }
    .auth_button_wrapper .auth_button.auth_button_outlined:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      border-radius: 26.78px;
      background-color: rgba(255, 255, 255, 0.25);
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      -webkit-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5); }
    .auth_button_wrapper .auth_button.auth_button_outlined:hover:after {
      opacity: 1;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1); }

.auth_button_profile {
  border-radius: 74px !important;
  width: 189px;
  height: 40px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important;
  line-height: 40px !important; }

.auth_button_profile_chat {
  border-radius: 74px !important;
  width: 120px;
  height: 40px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important;
  margin-left: 13px !important;
  line-height: 40px !important; }

.smaller {
  width: 204px; }

.auth_button_toast {
  border-radius: 27px !important;
  width: 90px !important;
  min-width: unset !important;
  height: 34px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important;
  line-height: 34px !important; }

.snack_root {
  margin-bottom: 20px; }
  .snack_root.error_snack div {
    background-color: #FF4D62;
    font-family: "Inter", sans-serif;
    max-width: none; }
    .snack_root.error_snack div svg {
      fill: #ffffff; }
  .snack_root.game_center_error_snack {
    margin-left: 120px; }
    .snack_root.game_center_error_snack div {
      background-color: #FF4D62;
      font-family: "Inter", sans-serif; }
      .snack_root.game_center_error_snack div svg {
        fill: #ffffff; }
  .snack_root.success_snack div {
    background-color: #28A745;
    font-family: "Inter", sans-serif; }
    .snack_root.success_snack div svg {
      fill: #ffffff; }
  .snack_root.game_over_info_snack > div {
    text-align: center;
    flex-wrap: nowrap;
    background-color: #0E1832;
    font-family: "Inter", sans-serif;
    border: 1px solid #373947;
    color: #737A93;
    letter-spacing: -0.266667px;
    font-size: 12px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
    max-width: none; }
    .snack_root.game_over_info_snack > div svg {
      fill: #ffffff; }
  .snack_root.panel_margin {
    margin-left: 120px; }

.auth_container {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-evenly;
  background-color: #1B2664;
  height: 100vh; }

.auth_section {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.auth_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh; }

.auth_section_block {
  background: #051745;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  padding: 80px 64px;
  width: 528px; }
  .auth_section_block form .custom_input_wrapper {
    margin-bottom: 20px; }

.auth_title {
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 15px; }

.auth_subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #A0A6D0;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 39px; }

.auth_buttons_wrapper {
  margin-top: 46px;
  display: flex;
  align-items: center;
  justify-content: center; }

